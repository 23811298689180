import Vue from 'vue'
import VueRouter from 'vue-router'
import aboutme from "../views/mine/component/aboutme.vue";
import slideshow from "../views/mine/component/slideshow.vue";
import leadershipcare from "../views/mine/component/leadershipcare.vue";
import uservoice from "../views/mine/component/uservoice.vue";

import serviceproject from "../views/mine/component/serviceproject.vue";
import contactus from "../views/mine/component/contactus.vue";
import joinus from "../views/mine/component/joinus.vue";

import notice from "../views/news/component/notice.vue";
import trends from "../views/news/component/trends.vue";
import voc from "../views/news/component/voc.vue";


Vue.use(VueRouter)

const routes = [
  // {
  //   path: '/',
  //   name: 'home',
  //   component: HomeView
  // },
  {
    path: '/',
    name: 'home',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/index/index.vue')
  },
  {
    path: '/kelist',
    name: 'kelist',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/news/kelist.vue')
  },
  {
    path: '/newlist',
    name: 'newlist',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/news/newlist.vue')
  },
  {
    path: '/login',
    name: 'login',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/login.vue')
  },
  {
    path: '/register',
    name: 'register',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/register.vue')
  },
  {
    path: '/backpass',
    name: 'backpass',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/backpass.vue')
  },
  {
    path: '/lecturer',
    name: 'searchlecturer',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/searchlecturer/index.vue')
  },
  {
    path: '/taglist',
    name: 'taglist',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/taglist/index.vue')
  },
  {
    path: '/course',
    name: 'searchcourse',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/searchcourse/index.vue')
  },
  {
    path: '/kelearning',
    name: 'kelearning',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/kelearning/index.vue'),
    mate:{
      keepAlive:false,
    },
  },
  {
    path: '/live',
    name: 'live',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/kelearning/live.vue')
  },
  {
    path: '/courseshow',
    name: 'classcourse',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/classcourse/index.vue')
  },
  {
    path: '/professor',
    name: 'professor',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/bigprofessor/index.vue')
  },
  {
    path: '/professorlist',
    name: 'professorlist',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/bigprofessorlist/index.vue')
  },  {
    path: '/Personal',
    name: 'Personal',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Personal/index.vue')
  },
  
  {
    path: '/mine',
    name: 'mine',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/mine/index.vue'),
    redirect: '/aboutme',
    children: [
      {
        path: '/aboutme',
        name: 'aboutme',
        component: aboutme,
      },
      {
        path: '/slideshow',
        name: 'slideshow',
        component: slideshow,
      },
      {
        path: '/leadershipcare',
        name: 'leadershipcare',
        component: leadershipcare,
      },
      {
        path: '/serviceproject',
        name: 'serviceproject',
        component: serviceproject,
      },
      {
        path: '/joinus',
        name: 'joinus',
        component: joinus,
      },
      {
        path: '/uservoice',
        name: 'uservoice',
        component: uservoice,
      },
      {
        path: '/contactus',
        name: 'contactus',
        component: contactus,
      },
    ]
  },
  {
    path: '/news',
    name: 'news',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/news/index.vue'),
    redirect: 'notice',
    children: [
      {
        path: '/notice',
        name: 'notice',
        component: notice,
      },
      {
        path: '/trends',
        name: 'trends',
        component: trends,
      },
      {
        path: '/voc',
        name: 'voc',
        component: voc,
      },
    ]}
]
const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    return {
        x: 0,
        y: 0
    }
}

})

const originalPush = VueRouter.prototype.push
console.log(originalPush)
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

// const router = new VueRouter({
  
//   routes
// })

export default router
