
import request from '@/utils/request'

export function news(data) {
    return request({
        url: '/pc/News/getList',        
        method: 'post',
        data
        
    })
}

export function voclist(data) {
    return request({
        url: '/pc/News/getUserVoiceList',        
        method: 'post',
        data
        
    })
}
//文章详情

export function getDetail(data) {
    return request({
        url: '/pc/News/getDetail',        
        method: 'post',
        data
        
    })
}

